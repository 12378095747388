import Sell1 from "../../images/sell1.jpg";
import Sell2 from "../../images/sell2.png";
import Sell3 from "../../images/sell3.png";

export const SellData = [
  {
    img: Sell1,
    step: "Step 1",
    card_title: "We Gather a Few Details",
    card_title_tag:
      "We’ll ask you to provide your home address, of course. And then we’ll need some contact information to know where to send the report.",
    btn_val: "",
    remove: "remove",
  },
  {
    img: Sell2,
    step: "Step 2",
    card_title: " We Examine the Data",
    card_title_tag:
      "We’ll get to work analyzing home sales in your neighborhood, your home’s unique features, what’s happening in the market, and other data to calculate an estimated home value.",
    btn_val: "",
    remove: "remove",
  },
  {
    img: Sell3,
    step: "Step 3",
    card_title: " We Send You the Report",
    card_title_tag:
      "You’ll receive a comprehensive report detailing our analysis and estimated value for your home. You can use the report to decide whether to sell your home in Normal Heights or to help you plan for the future.",
    btn_val: "Get My VALUATION",
    remove: "",
  },
];
